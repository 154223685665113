'use client';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// routes
import { useSearchParams, useRouter } from 'src/routes/hooks';
// config
import { PATH_AFTER_LOGIN } from 'src/config-global';
// auth
import { useAuthContext } from 'src/auth/hooks';
import TraLink from "src/components/link/link";
// components
import Iconify from 'src/components/iconify';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { useSignupContext } from 'src/components/signup-popup/context/signup-context';

// ----------------------------------------------------------------------

export default function EmailRegisterForm({ isOpen = false, onIsOpenChanged }) {

  const { register } = useAuthContext();
  const signup = useSignupContext();

  const router = useRouter();

  const [errorMsg, setErrorMsg] = useState('');

  const searchParams = useSearchParams();

  const returnTo = searchParams.get('returnTo');

  const password = useBoolean();

  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    name: '',
    email: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await register?.(data.email, data.password, data.name);
      signup.onClose();

      const redirect = returnTo || signup?.redirect || PATH_AFTER_LOGIN;
      if(redirect !== 'none'){
        router.push(redirect);
      }
    } catch (error) {
      console.error(error);
      reset();
      setErrorMsg(typeof error === 'string' ? error : error.message);
    }
  });

  const renderTerms = (
    <Typography
      component='div'
      sx={{
        color: 'text.secondary',
        mt: 1,
        typography: 'caption',
        textAlign: 'center',
      }}
    >
      <TraLink underline='always' sx={{color: 'text.primary', textDecorationColor: 'gray'}} href='/policies/terms/'>
        Terms & Conditions
      </TraLink>
      {', '}
      <TraLink underline='always' sx={{color: 'text.primary', textDecorationColor: 'gray'}} href='/policies/dpa/'>
        DPA
      </TraLink>
      {' and '}
      <TraLink underline='always' sx={{color: 'text.primary', textDecorationColor: 'gray'}} href='/policies/privacy-policy/'>
        Privacy Policy
      </TraLink>
      .
    </Typography>
  );

  const textFieldInputSx = {
    background: (theme) => theme.palette.background.paper,
    height: 40,
  };

  const renderForm = (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack spacing={1.25}>
        {!!errorMsg && <Alert severity='error'>{errorMsg}</Alert>}

        <RHFTextField name='name' label='Your name' size='small' InputProps={{ sx: textFieldInputSx }} />

        <RHFTextField name='email' label='Email address' size='small' InputProps={{ sx: textFieldInputSx }} />

        <RHFTextField
          name='password'
          label='Password'
          size='small'
          type={password.value ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton onClick={password.onToggle} edge='end'>
                  <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                </IconButton>
              </InputAdornment>
            ),
            sx: textFieldInputSx,
          }}
        />

        <LoadingButton
          fullWidth
          color='inherit'
          size='default'
          type='submit'
          variant='contained'
          loading={isSubmitting}
        >
          Create account
        </LoadingButton>
      </Stack>
    </FormProvider>
  );

  return (<>
      {!isOpen && <Button
        onClick={() => onIsOpenChanged(true)}
        startIcon={<Iconify icon='solar:letter-linear' />}
        sx={{ background: (theme) => theme.palette.background.paper, border: (theme) => `1px solid ${theme.palette.divider}` }}>
        Continue with email
      </Button>}
      {isOpen &&
      <Stack>
        {isOpen && <Stack direction='row'>
          <Button
            size='small'
            onClick={() => onIsOpenChanged(false)}
            startIcon={<Iconify icon='solar:alt-arrow-left-line-duotone' width={12} />}
            sx={{ mt: -2, mb: 0.5, width: 'auto' }}>
            Back
          </Button>
        </Stack>}

        {renderForm}

        {renderTerms}
      </Stack>}
    </>
  );
}

EmailRegisterForm.propTypes = {
  isOpen: PropTypes.bool,
  onIsOpenChanged: PropTypes.func,
};

