'use client';

import PropTypes from 'prop-types';
import { useMemo, useCallback, useState } from 'react';
// hooks
// utils
//
import { SignupContext } from './signup-context';

// ----------------------------------------------------------------------

const DEFAULT_MODE = 'register';
const DEFAULT_REDIRECT = '/dashboard/tracking';
const DEFAULT_CLOSEABLE = true;

export function SignupProvider({ children }) {
  const [openPopup, setOpenPopup] = useState(false);
  const [popupMode, setPopupMode] = useState(DEFAULT_MODE);
  const [popupRedirect, setPopupRedirect] = useState(DEFAULT_REDIRECT);
  const [popupCloseable, setPopupCloseable] = useState(DEFAULT_REDIRECT);

  // Drawer
  const onTogglePopup = useCallback(
    (mode = DEFAULT_MODE, redirect = DEFAULT_REDIRECT, closeable = DEFAULT_CLOSEABLE) => {
      setPopupMode(mode || DEFAULT_MODE);
      setPopupRedirect(redirect || DEFAULT_REDIRECT);
      setPopupCloseable(closeable);

      setOpenPopup((prev) => !prev);
    },
    []
  );

  const onOpenPopup = useCallback(
    (mode = DEFAULT_MODE, redirect = DEFAULT_REDIRECT, closeable = DEFAULT_CLOSEABLE) => {
      setPopupMode(mode || DEFAULT_MODE);
      setPopupRedirect(redirect || DEFAULT_REDIRECT);
      setPopupCloseable(closeable);

      setOpenPopup(true);
    },
    []
  );

  const onClosePopup = useCallback(() => {
    setOpenPopup(false);
  }, []);

  const memoizedValue = useMemo(
    () => ({
      open: openPopup,
      mode: popupMode,
      onOpen: onOpenPopup,
      onToggle: onTogglePopup,
      onClose: onClosePopup,
      redirect: popupRedirect,
      closeable: popupCloseable,
    }),
    [openPopup, popupMode, onOpenPopup, onClosePopup, onTogglePopup, popupRedirect, popupCloseable]
  );

  return <SignupContext.Provider value={memoizedValue}>{children}</SignupContext.Provider>;
}

SignupProvider.propTypes = {
  children: PropTypes.node,
};
